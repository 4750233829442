import React from 'react';

import { ProductHero } from '@root/components/www/shared/product_hero';
import { Footer } from '@shared/content/footer';
import { ContentWithSideImage } from '@shared/page_builder/components/content_with_side_image';
import { IconValueProps } from '@shared/page_builder/components/icon_value_props';
import { ServiceSelection } from '@shared/service_selector/constants';
import { ZebraContainer, ZebraPattern } from '@shared/zebra_container';

import { Header } from '../../../shared/header/header';
import { FAQCategories } from '../faq_categories';

import facility from '@images_responsive/www/services/facility.jpg';
import moversLoadingBike from '@images_responsive/www/services/movers_loading_bike.jpg';
import moversLoadingVan from '@images_responsive/www/services/movers_loading_van.jpg';
import movingHero from '@images_responsive/www/services/storage_and_moving_hero.jpg';
import calendar from '@images/illustrations/calendar.svg';
import clutterTruck from '@images/illustrations/mover_with_truck.svg';
import onlineQuote from '@images/illustrations/online_quote_check.svg';

const valueProps = [
  {
    title: 'Do everything online',
    description:
      'Your rate covers all packing at point A, unpacking at point B, and secure transport from A to B.',
    illustration: clutterTruck,
  },
  {
    title: 'Save big with Smart Storage',
    description: 'Smart Storage means you’ll only pay for the space you use.',
    illustration: calendar,
  },
  {
    title: 'We handle the whole process',
    description:
      'Your items will arrive within a 1-hour window. You can track your ETA in real time.',
    illustration: onlineQuote,
  },
];

const faqContent = [
  {
    question: 'Can you store items and move items?',
    answer: (
      <>
        Yes! With operations nationally, Clutter can meet any storage and/or
        moving need you have.
      </>
    ),
  },
  {
    question: 'Which Clutter service is right for me?',
    answer: (
      <>
        If you need items stored after your move, then use Clutter’s moving
        service. When scheduling your move (or at any time before your move is
        completed) you can choose to add items to storage and get your first
        month free. If you need everything stored in between your move, then use
        Clutter’s Smart Storage product.
      </>
    ),
  },
  {
    question: 'Can you store items after my move?',
    answer: (
      <>
        Yes, Clutter can transfer items from your move into storage on the day
        of your move. No additional pickups necessary. Simply add a storage plan
        when scheduling your move and get the first month free!
      </>
    ),
  },
  {
    question:
      'Can you pick everything up from my home, store it, then move it to my new home?',
    answer: (
      <>
        Yes! Clutter’s Smart Storage product was made exactly for this! Clutter
        has month-to-month and longer-term storage plans. This allows you to
        move into your new home on your schedule.
      </>
    ),
  },
  {
    question: 'How much do storage and moving cost? ',
    answer: (
      <>
        Clutter has flexible options that fit all budgets. Exact pricing depends
        on what services you need and how far items will be transported.
      </>
    ),
  },
];

export const StorageAndMovingServicePage = () => {
  return (
    <>
      <Header service={ServiceSelection.Moving} />
      <ZebraContainer zebra={ZebraPattern.LIGHT_THEN_DARK}>
        <ProductHero
          title="Storage and moving in one convenient place"
          subtitle="Moving and storing go hand in hand. Clutter has plans for movers that need storage, and storers that need moving."
          image={movingHero}
          service={ServiceSelection.Moving}
        />
        <ContentWithSideImage
          title="Store excess items after you move"
          subTitle="Once your move is complete, the Clutter team will transport your items into storage free of charge"
          imageAlignment="right"
          contentColumns={6}
          imageA={{ ...moversLoadingVan, widthPercent: 50 }}
          imageB={{ ...facility, widthPercent: 46 }}
          cta={{
            service: ServiceSelection.Moving,
            container: 'move_first',
            label: 'Get Started',
          }}
          content={[
            {
              bulletPoints: [
                'Free month of storage when you complete a move',
                'No minimum term commitment for your free month',
              ],
            },
          ]}
        />
        <ContentWithSideImage
          content={[
            {
              bulletPoints: [
                'Delivery of everything all at once or one shipment at a time',
                'Your stored items delivered to anywhere in the U.S.',
              ],
            },
          ]}
          contentColumns={6}
          imageA={moversLoadingBike}
          imageAlignment={'left'}
          title="Store first, move when you’re ready"
          subTitle="Many moving customers need temporary storage before they move to a new place. We can help!"
          cta={{
            service: ServiceSelection.Storage,
            container: 'store_first',
            label: 'Get Started with Storage',
          }}
        />
        <IconValueProps
          title="Effortless, affordable moving"
          valueProps={valueProps}
          cta={{ service: ServiceSelection.Moving }}
        />
        <FAQCategories
          headline={<>Common questions about moving.</>}
          categories={[{ questions: faqContent }]}
        />
      </ZebraContainer>
      <Footer />
    </>
  );
};
